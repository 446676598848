import { Injectable } from '@angular/core';
import { Variant } from '@app/models/menu/variant';
import { OrderOccasion } from '@app/models/order-occasion';
import { MenuHelperService } from '@app/shared/services/menu-helper/menu-helper.service';
import { OccasionPrice } from '@app/models/menu/occasion-price';
import { Nutrition } from '@app/models/menu/nutrition';
import { BasketUpselling } from '@app/models/basket/basket-upselling';
import { BasketUpsellingMenuItem } from '@app/models/basket/basket-upselling-menu-item';
import { Deal } from '@app/models/menu/deal';
import { Menu } from '@app/models/menu/menu';
import { Product } from '@app/models/menu/product';

@Injectable({
  providedIn: 'root'
})
/**
 * Service to assist with menu variant operations such as checking availability,
 * determining if a product is simple, and generating nutrition and price summaries.
 */
export class MenuVariantHelperService {
  constructor(
    private _menuHelperService: MenuHelperService
  ) { }

  /**
   * Checks if there are any variants available for a given occasion and time.
   * @param variants - Array of variants to check.
   * @param occasion - The occasion to check availability for.
   * @param wantedTime - The time to check availability for.
   * @returns True if any variants are available for the given occasion and time, otherwise false.
   */
  public areVariantsAvailableForOccasion(variants: Variant[], occasion: OrderOccasion, wantedTime: Date): boolean {
    return variants.some((variant: Variant) =>
      variant.Prices.some((x: OccasionPrice) => x.Occasion === occasion)
      && (!variant.Availability || this._menuHelperService.availableForOccasion(variant.Availability.Values, occasion, wantedTime))
    );
  }

  /**
   * Determines if a product is simple, meaning it has no modifiers and only one variant.
   * @param variants - Array of product variants.
   * @returns True if the product is simple, otherwise false.
   */
  public isProductSimple(variants: Variant[]): boolean {
    return variants.length === 1 && !variants[0].Modifiers;
  }

  /**
   * Generates a nutrition summary for the given variants.
   * @param variants - Array of variants to generate the summary for.
   * @param showMax - Whether to show the maximum values in the summary.
   * @returns A string summarizing the nutrition information.
   */
  public getNutritionSummary(variants: Variant[], showMax: boolean): string {
  // Extract non-null nutrition objects from the variants
    const nutrition: Nutrition[] = variants
        .map((variant: Variant) => variant.Nutrition)
        .filter((x: Nutrition) => !!x);

    if (nutrition.length === 0) {
      return '';
    }

    if (nutrition.length === 1) {
      return `${nutrition[0].Calories} kcal • serves ${nutrition[0].NumberOfPortions}`;
    }

    const minCalories: number = Math.min(...nutrition.map((n: Nutrition) => n.Calories));
    const maxCalories: string = showMax ? `-${Math.max(...nutrition.map((n: Nutrition) => n.Calories))}` : '';
    const minPortions: number = Math.min(...nutrition.map((n: Nutrition) => n.NumberOfPortions));
    const maxPortions: string = showMax ? `-${Math.max(...nutrition.map((n: Nutrition) => n.NumberOfPortions))}` : '';

    return `From ${minCalories}${maxCalories} kcal • serves ${minPortions}${maxPortions}`;
  }

  /**
   * Generates a price and a price summary for the given variants and occasion.
   * @param variants - Array of variants to generate the summary for.
   * @param occasion - The occasion to generate the price summary for.
   * @param includeFromText - Whether to include the "from" text in the summary.
   * @param isInDeal - Whether product is in a deal.
   * @returns A string summarizing the price information.
   */
  public getPriceAndSummaryForVariants(
      variants: Variant[],
      occasion: OrderOccasion,
      includeFromText: boolean = true,
      isInDeal: boolean = false
  ): { price: number, summary: string } {
    const summary: string = this.getPriceSummaryForVariants(variants, occasion, includeFromText, isInDeal);
    return { price: +(summary.split('from ').join('').split('£').join('')), summary };
  }

  /**
   * Generates a price summary for the given variants and occasion.
   * @param variants - Array of variants to generate the summary for.
   * @param occasion - The occasion to generate the price summary for.
   * @param includeFromText - Whether to include the "from" text in the summary.
   * @param isInDeal - Whether product is in a deal.
   * @returns A string summarizing the price information.
   */
  public getPriceSummaryForVariants(variants: Variant[], occasion: OrderOccasion, includeFromText: boolean = true, isInDeal: boolean = false): string {
    if (!variants || variants.length === 0) {
      return null;
    }

    if (isInDeal) {
      const dealPremium: number = variants.find((x: Variant) => x.DealPremium > 0)?.DealPremium || 0;
      return dealPremium ? `+£${dealPremium.toFixed(2)}` : null;
    }

    // should be configurable?
    const defaultVariant = this.getDefaultVariant(variants);
    if (defaultVariant) {
      return `£${this.getMinPriceForVariants([defaultVariant], occasion).toFixed(2)}`;
    }

    const min: number = this.getMinPriceForVariants(variants, occasion);

    if (min === this.getMaxPriceForVariants(variants, occasion)) {
      return `£${min.toFixed(2)}`;
    }

    return `${includeFromText ? 'from ' : ''}£${min.toFixed(2)}`;
  }

  /**
   * Gets the minimum price for the given variants and occasion.
   * @param variants - Array of variants to check.
   * @param occasion - The occasion to get the minimum price for.
   * @returns The minimum price for the given variants and occasion.
   */
  public getMinPriceForVariants(variants: Variant[], occasion: OrderOccasion): number {
    const prices: number[] = variants
        .map((variant: Variant) => this._menuHelperService.getPriceForOccasion(variant.Prices, occasion))
        .filter((x: number) => x && x !== 0);

    if (prices.length === 0) {
      return 0;
    }

    return Math.min(...prices);
  }

  /**
   * Gets the default variant for the given variants.
   * @param variants - Array of variants to check.
   * @returns The default variant.
   */
  public getDefaultVariant(variants: Variant[]): Variant {
    return variants.find((v: Variant) => v.PreferredDefault) || null;
  }

  /**
   * Gets the upsell options for the given upselling, menu, occasion, and wanted time.
   */
  public getBasketUpsellOptions(upselling: BasketUpselling, menu: Menu, occasion: OrderOccasion, wantedTime: Date): { prompt: string, options: (Product | Deal)[] } {
    if (!upselling || upselling.MenuItems.length === 0) {
      return null;
    }

    const menuItems: (Product | Deal)[] = upselling?.MenuItems
        .map((x: BasketUpsellingMenuItem) => {
          if (x.Type === 0) {
            const product = menu.Products.find((y: Product) => y.Id === x.Id);

            if (!product || !this.areVariantsAvailableForOccasion(product.Variants, occasion, wantedTime) || product.Variants.every((y) => y.OutOfStock)) {
              return null;
            }

            return product;
          }

          const deal = menu.Deals.find((y: Deal) => y.Id === x.Id);

          if (!deal || !this._menuHelperService.availableForOccasion(deal.Availability?.Values, occasion, wantedTime)) {
            return null;
          }

          return deal;
        })
        .filter((x: Product | Deal | null) => !!x) as (Product | Deal)[];

    const upsellProducts: (Product | Deal)[] = menuItems.sort((a: Product | Deal, b: Product | Deal) => (a?.Sequence || 0) - (b?.Sequence || 0));

    if (upsellProducts.length === 0) {
      return null;
    }

    return { prompt: upselling.Prompt ?? 'Before you go', options: upsellProducts };
  }

  /**
   * Gets the maximum price for the given variants and occasion.
   * @param variants - Array of variants to check.
   * @param occasion - The occasion to get the maximum price for.
   * @returns The maximum price for the given variants and occasion.
   */
  private getMaxPriceForVariants(variants: Variant[], occasion: OrderOccasion): number {
    const prices: number[] = variants
        .map((variant: Variant) => variant.Prices.find((x: OccasionPrice) => x.Occasion === occasion)?.Amount)
        .filter((x: number) => x && x !== 0);

    if (prices.length === 0) {
      return 0;
    }

    return Math.max(...prices);
  }
}
