import { Injectable } from '@angular/core';
import { IAnalyticsProvider } from '@app/models/app-initialisers/IAnalyticsProvider';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticService implements IAnalyticsProvider {
  private _eventQueue: { eventName: string; params: any, skipFirstParam: boolean }[] = [];
  private _clientTrackingId: string;
  private _enabled = false;

  private _gtagAttempts: number = 0;

  constructor(
    public configurationService: ConfigurationService
  ) { }

  /**
   * Initializes Google Analytics.
   * @returns A subscription to the tenant readiness status.
   */
  public googleAnalyticInit(): Subscription {
    return this.configurationService.isTenantReady.subscribe((status: boolean) => {
      if (status) {
        this._clientTrackingId = this.configurationService.getGoogleAnalyticsIdClient();
        this.waitForGtag();
      }
    });
  }

  /**
   * Tracks a page view.
   * @param path - The path of the page.
   * @param title - The title of the page.
   */
  public pageView(path: string, title: string): void {
    if (!this._enabled) return;

    const eventParams = {
      page_path: path,
      page_title: title
    };

    if (this._clientTrackingId) {
      eventParams['send_to'] = this._clientTrackingId;
    }

    this.trackEvent('page_view', eventParams);
  }

  /**
   * Tracks an exception.
   * @param exception The exception object.
   * @param isFatal Indicates if the exception is fatal.
   */
  public trackException(exception: Error, isFatal: boolean): void {
    if (!this._enabled) return;

    const eventParams = {
      description: exception.message,
      fatal: isFatal
    };

    this.addSendToTag(eventParams);

    this.trackEvent('exception', eventParams);
  }

  /**
   * Sets up the user ID for tracking.
   * @param userId The user ID.
   */
  public setupUserId(userId: string): void {
    if (!this._enabled) return;

    const eventParams = { 'user_id': userId };
    this.addSendToTag(eventParams);

    this.trackEvent('set', eventParams, true);
  }

  /**
   * Tracks performance.
   * @param category The category of the performance event.
   * @param path The path of the performance event.
   * @param time The time duration of the performance event.
   * @param label The label for the performance event.
   */
  public trackPerformance(category: string, path: string, time: number, label?: string): void {
    if (!this._enabled) return;

    const eventParams = {
      event_category: category,
      event_label: label,
      name: path,
      value: time
    };

    this.addSendToTag(eventParams);

    this.trackEvent('timing_complete', eventParams);
  }

  /**
   * Tracks the view of a product.
   * @param productName The name of the product.
   * @param productId The ID of the product.
   * @param category The category of the product.
   * @param price The price of the product.
   * @param currency The currency of the product price.
   */
  public trackViewProduct(
      productName: string,
      productId: string,
      category: string,
      price: number,
      currency: string,
      variant: string,
      coupon: string
  ): void {
    if (!this._enabled) return;

    const item: GoogleAnalyticsItem = {
      item_brand: this.getHostname(),
      item_category: category,
      item_id: productId,
      item_name: productName,
      price: price,
      item_variant: variant
    };

    if (coupon) {
      item.coupon = coupon;
    }

    const eventParams = {
      currency: currency,
      value: price,
      items: [item]
    };

    this.addSendToTag(eventParams);

    this.trackEvent('view_item', eventParams);
  }

  /**
   * Adds the deal to the gtags event for google analytics.
   * @param dealId The ID of the deal.
   * @param dealName The name of the deal.
   * @param category The category of the deal.
   * @param currency The currency of the deal.
   */
  public trackViewDeals(
      dealId: string,
      dealName: string,
      items: [GoogleAnalyticsItem]
  ): void {
    if (!this._enabled) return;

    const eventParams = {
      promotion_name: dealName,
      promotion_id: dealId,
      items
    };

    this.addSendToTag(eventParams);

    this.trackEvent('view_promotion', eventParams);
  }

  /**
   * Tracks the click on a product.
   * @param productName The name of the product.
   * @param productId The ID of the product.
   * @param category The category of the product.
   * @param price The price of the product.
   * @param currency The currency of the product price.
   */
  public trackClickProduct(
      productName: string,
      productId: string,
      category: string,
      price: number,
      currency: string
  ): void {
    if (!this._enabled) return;

    const eventParams = {
      currencyCode: currency,
      event_category: 'engagement',
      event_label: 'productClick',
      items: [
        {
          item_brand: this.getHostname(),
          item_category: category,
          item_id: productId,
          item_name: productName,
          price: price
        }
      ]
    };

    this.addSendToTag(eventParams);

    this.trackEvent('select_item', eventParams);
  }

  public trackViewBasket(value: number, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    const eventParams = { currency: 'GBP', value, items };
    this.addSendToTag(eventParams);

    this.trackEvent('view_cart', eventParams);
  }

  public trackAddToBasket(value: number, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    const eventParams = { currency: 'GBP', value, items };
    this.addSendToTag(eventParams);

    this.trackEvent('add_to_cart', eventParams);
  }

  /**
   * Tracks the addition or removal of a product from the basket.
   * @param added Indicates if the product was added (true) or removed (false).
   * @param productName The name of the product.
   * @param productId The ID of the product.
   * @param category The category of the product.
   * @param price The price of the product.
   * @param currency The currency of the product price.
   */
  public trackRemoveFromBasket(value: number, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    this.trackEvent('remove_from_cart', { currency: 'GBP', value, items });
  }

  /**
   * Tracks the checkout process.
   * @param products The list of products in the checkout.
   * @param currency The currency of the products.
   */
  public trackBeginCheckout(value: number, coupon: string, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    const eventParams = { value, coupon, items };
    this.addSendToTag(eventParams);

    this.trackEvent('begin_checkout', eventParams);
  }

  /**
   * Tracks a purchase event.
   * @param orderId The ID of the order.
   * @param amount The total amount of the purchase.
   * @param tax The tax amount of the purchase.
   * @param deliveryCharge The delivery charge amount of the purchase.
   * @param voucher The voucher code used in the purchase.
   * @param occasion The occasion of the purchase.
   * @param products The list of products in the purchase.
   */
  public trackPurchaseAndClose(amount: number, orderId: string, voucherCode: string, items: GoogleAnalyticsItem[]): void {
    if (!this._enabled) {
      return;
    }

    const eventParams = {
      currency: 'GBP',
      value: amount,
      transaction_id: orderId,
      coupon: voucherCode,
      items: items
    };

    this.addSendToTag(eventParams);

    this.trackEvent('purchase', eventParams);

    const closeEventParams = { currency: 'GBP', value: amount };
    this.addSendToTag(closeEventParams);
    this.trackEvent('close_convert_lead', closeEventParams);
  }

  private waitForGtag(): void {
    this._gtagAttempts += 1;

    try {
      gtag('js', new Date());
      this._enabled = true;
      this.flushEventQueue();
    } catch (e) {
      console.log(`%cGA disabled.`, 'color: orange; font-size: 13px');

      if (this._gtagAttempts < 10) {
        setTimeout(() => this.waitForGtag(), 500);
      }
    }
  }

  private flushEventQueue(): void {
    while (this._eventQueue.length > 0) {
      const { eventName, params, skipFirstParam } = this._eventQueue.shift()!;

      if (skipFirstParam) {
        gtag(eventName, params);
      } else {
        gtag('event', eventName, params);
      }
    }
  }

  private trackEvent(eventName: string, params: any, skipFirstParam: boolean = false): void {
    if (this._enabled && typeof gtag === 'function') {
      if (skipFirstParam) {
        gtag(eventName, params);
      } else {
        gtag('event', eventName, params);
      }
    } else {
      console.warn(`Queuing event: ${eventName} as gtag is not ready.`);
      this._eventQueue.push({ eventName, params, skipFirstParam });
    }
  }

  /**
   * Retrieves the hostname of the current window location.
   * @returns The hostname without the "www" prefix.
   */
  private getHostname(): string {
    return window.location.hostname.replace('www.', '');
  }

  private addSendToTag(eventParams: any): void {
    if (this._clientTrackingId) {
      eventParams['send_to'] = this._clientTrackingId;
    }
  }
}
