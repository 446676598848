import { Component, computed, forwardRef, Inject, Signal, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MenuService } from '@app/api/menu.service';
import { Product } from '@app/models/menu/product';
import { Menu } from '@app/models/menu/menu';
import { takeUntil } from 'rxjs';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { toSignal } from '@angular/core/rxjs-interop';
import { Variant } from '@app/models/menu/variant';
import { PipesModule } from '@app/modules/pipes/pipes-module';
import { SharedImportsModule } from '@app/shared/shared-imports.module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { MenuHelperService } from '@app/shared/services/menu-helper/menu-helper.service';
import { MenuVariantHelperService } from '@app/shared/services/menu-variant-helper/menu-variant-helper.service';
import { QuantityComponent } from '@app/modules/menu/components/quantity/quantity.component';

import { ProductView } from '@app/models/product/product-view';
import { ProductComponent } from '@app/shared/components/product/product.component';
import { ProductAddEvent } from '@app/models/product/product-add-event';
import { UpsellProduct } from '@app/models/menu/upsell-product';
import { Deal } from '@app/models/menu/deal';
import { MenuDealHelperService } from '@app/shared/services/menu-deal-helper/menu-deal-helper.service';
import { DealsComponent } from '@app/shared/components/deals/deals.component';
import { ProductUpsellModalData } from '@app/models/product/product-upsell-modal-data';

@Component({
  selector: 'app-product-upsell-modal',
  templateUrl: './product-upsell-modal.component.html',
  styleUrls: ['./product-upsell-modal.component.scss'],
  standalone: true,
  imports: [
    SharedMaterialModule,
    SharedImportsModule,
    PipesModule,
    QuantityComponent,
    forwardRef(() => ProductComponent),
    DealsComponent
  ]
})
export class ProductUpsellModalComponent extends AndroWebCoreComponent {
  @ViewChild('ProductModal') private _productModalTemplate: TemplateRef<any>;
  @ViewChild('DealModal') private _dealModalTemplate: TemplateRef<any>;

  private _menu: Signal<Menu> = toSignal(this._menuService.menu$.pipe(takeUntil(this.destroy$)));
  private _productModalRef: MatDialogRef<any>;
  private _dealModalRef: MatDialogRef<any, any>;

  public price: number = 0;
  public isLoading: boolean = false;
  public productView: ProductView;
  public readonly upsellProducts = computed(() => this.getAvailableUpsellProducts(this._menu()));
  public selectedDeal: Deal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductUpsellModalData,
    private _dialogRef: MatDialogRef<ProductUpsellModalComponent>,
    private _menuService: MenuService,
    private _menuHelperService: MenuHelperService,
    private _menuDealHelperService: MenuDealHelperService,
    private _menuVariantHelperService: MenuVariantHelperService
  ) {
    super();
  }

  public decreaseQuantity(option: UpsellProduct): void {
    if (option.quantity > 0) {
      option.quantity -= 1;
    }

    this.updatePrice();
  }

  public increaseQuantity(option: UpsellProduct): void {
    option.quantity += 1;
    this.updatePrice();
  }

  /**
   * Adds the upsell simple products to the basket
   */
  public addProductToBasket(): void {
    this.isLoading = true;
    for (const product of this.upsellProducts().filter((x: UpsellProduct) => x.quantity > 0)) {
      this.data.save({
        item: {
          Modifiers: [],
          Product: {
            Item: product.id,
            Quantity: product.quantity
          }
        },
        itemPrice: product.price,
        totalPrice: product.price * product.quantity
      }, product.name);
    }
    this.isLoading = false;
    this._dialogRef.close();
  }

  /**
   * Opens the product modal for a complex product
   * @param item
   * @param line
   */
  public openProductModal(item: UpsellProduct): void {
    if (item.deal) {
      this.selectedDeal = item.deal;

      const config: MatDialogConfig<any> = {
        height: this.isMobile ? '100%' : '',
        panelClass: ['custom-dialog-two-container', 'product-modal'],
        width: this.isMobile ? '100%' : '450px',
        ariaModal: false
      };

      this._dealModalRef = this.openDialog(this._dealModalTemplate, 'productsModal', config);
      return;
    }

    this.productView = {
      name: item.product.Name,
      description: item.product.Description,
      allergens: this._menuHelperService.getAllergenSummary(item.product.Allergens),
      variants: item.product.Variants,
      imageSource: this.getMenuImageVisualAsset({ data: item.product, height: 244 }),
      isInDeal: false,
      save: (event: ProductAddEvent) => {
        this.data.save(event, item.product.Name);
        this._productModalRef.close();
        return Promise.resolve();
      }
    };

    const options: MatDialogConfig<void> = {
      autoFocus: false,
      height: this.isMobile ? '100%' : '',
      maxHeight: '100%',
      panelClass: ['custom-dialog-two-container', 'product-modal'],
      width: this.isMobile ? '100%' : '450px'
    };

    this._productModalRef = this.openDialog(this._productModalTemplate, 'complexProductModal', options);
  }

  /**
  * Closes the complex product modal
  */
  public closeProductModal(): void {
    this.productView = null;
    this._productModalRef.close();
  }

  /**
  * Closes the complex product modal
  */
  public closeDealModal(): void {
    this.selectedDeal = null;
    this._dealModalRef.close();
  }

  /**
   * Closes this upsell modal
   */
  public cancel(): void {
    this._dialogRef.close();
  }

  private updatePrice(): void {
    this.price = this.upsellProducts().filter((x: UpsellProduct) => x.quantity > 0)
        .reduce((acc: number, product: UpsellProduct) => acc + (product.quantity * product.price), 0);
  }

  private getAvailableUpsellProducts(menu: Menu): UpsellProduct[] {
    if (!menu) {
      return [];
    }

    if (this.data.options) {
      return this.data.options
          .map((option: Product | Deal) => option['Lines'] ? this.mapDealToUpsellProduct(option as Deal) : this.mapProductToUpsellProduct(option as Product))
          .sort((a: UpsellProduct, b: UpsellProduct) => a.sequence - b.sequence);
    }

    return menu
        .Products.filter((x: Product) => this.data.productIds.includes(x.Id) && x.Variants.some((v: Variant) => !v.OutOfStock))
        .map((x: Product) => this.mapProductToUpsellProduct(x))
        .sort((a: UpsellProduct, b: UpsellProduct) => a.sequence - b.sequence);
  }

  /**
   * Maps a deal to an upsell product
   * @param deal
   */
  private mapDealToUpsellProduct(deal: Deal): UpsellProduct {
    return {
      name: deal.Name,
      deal,
      id: deal.Id,
      quantity: 0,
      sequence: deal.Sequence,
      price: null,
      priceSummary: this._menuDealHelperService.getPriceSummaryForDeal(deal, this.data.occasion),
      isProductSimple: false,
      isDeal: true
    };
  }

  /**
   * Maps a product to an upsell product
   * @param product
   */
  private mapProductToUpsellProduct(product: Product): UpsellProduct {
    const priceSummary = this._menuVariantHelperService.getPriceAndSummaryForVariants(product.Variants, this.data.occasion);

    return {
      name: product.Name,
      product,
      id: product.Id,
      quantity: 0,
      sequence: product.Sequence,
      price: priceSummary.price,
      priceSummary: priceSummary.summary,
      isProductSimple: this._menuVariantHelperService.isProductSimple(product.Variants),
      isDeal: false
    };
  }
}
