import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { SharedImportsModule } from '@app/shared/shared-imports.module';

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrl: './quantity.component.scss',
  standalone: true,
  imports: [SharedMaterialModule, SharedImportsModule]
})
export class QuantityComponent {
  @Input() value: number = 0;
  @Input() min: number = 0;
  @Input() showTrashBin: boolean = false;
  @Output() add = new EventEmitter();
  @Output() minus = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Input() max: number = 99;

  handleRemove() {
    this.remove.emit();
  }

  handleAdd() {
    if (this.value < this.max) {
      this.add.emit();
    }
  }

  handleMinus() {
    this.minus.emit();
  }
}
