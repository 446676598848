// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}
.quantity__number {
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  min-width: 2rem;
}

.select-AddOns-Quantity-plus.disabled, .select-AddOns-Quantity-minus.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.select-AddOns-Quantity-minus, .select-AddOns-Quantity-plus {
  align-items: center;
  color: #E3000E;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
  font-size: 18px;
  margin: 0 5px;
}
.select-AddOns-Quantity-minus .mat-icon, .select-AddOns-Quantity-plus .mat-icon {
  font-size: 22px;
  width: 22px;
  height: 22px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
