// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

section.product-upsell-modal {
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  position: relative;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}
section.product-upsell-modal h1, section.product-upsell-modal h2 {
  margin: 0;
  padding: 20px 20px 0;
}
section.product-upsell-modal ul {
  padding: 20px;
  list-style: none;
  margin: 0;
  max-height: calc(100vh - 185px);
  overflow-y: auto;
}

.product-upsell-modal__item {
  font-size: 14px;
  border: thin solid rgba(0, 0, 0, 0.12);
  margin: 5px 0 10px;
  padding: 16px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-radius: 8px;
  list-style: none;
}

.product-upsell-modal__price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.product-upsell-modal__footer {
  display: flex;
  min-height: 100;
  height: 100;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  z-index: 1;
  padding-top: 1px;
  background-color: #fff;
  margin-top: auto;
}
.product-upsell-modal__footer .button-cancel,
.product-upsell-modal__footer .button-cta {
  cursor: pointer;
  display: inline-block;
  line-height: unset;
  width: 100%;
  height: 48px;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 15px !important;
  font-size: 14px !important;
}
.product-upsell-modal__footer .button-cta {
  background-color: #E3000E;
}
.product-upsell-modal__footer .button-cta.mat-mdc-button {
  color: #fff;
}
.product-upsell-modal__footer .button-cta[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
